<template>
  <page-layout>
    <div slot="action" style="display: flex">
      <a-input-search
        v-model="keywords"
        placeholder="请输入关键词"
        style="width: 300px"
        enter-button
        @search="onSearch"
      />
      <div class="buy_cart" @click="$router.push({ path: 'buyCart' })">
        <span>购物车</span>
        <a-badge
          :count="cartNum"
          :number-style="{
            backgroundColor: 'transparent',
            color: '#3ab887',
            boxShadow: '0 0 0 1px #3ab887 inset',
            marginLeft: '9px',
            top: '-1px',
          }"
        />
      </div>
    </div>
    <div id="allGoods">
      <div class="up">
        <div class="up_left">
          <!-- 单品 套餐分类 -->
          <div class="title_box">
            <p
              v-for="(item, i) in classTitleList"
              :key="i"
              :class="selectClass == i ? 'selectP' : ''"
              @click="changeClass(i)"
            >
              {{ item }}
            </p>
          </div>
          <ul class="content">
            <a-spin :spinning="RankingSpinning">
              <div class="no_detail no_detail_l" v-if="RankingList.length == 0">
                <img src="@/assets/img/no_detail.png" alt="" />
                <span>暂无内容~</span>
              </div>
              <li
                v-for="(item, index) in RankingList"
                :key="index"
                @click="
                  $router.push({
                    path: 'goodsDetail',
                    query: { id: item.id },
                  })
                "
              >
                <span :class="['index', 'index' + index]">{{ index + 1 }}</span>
                <img style="object-fit: contain" :src="item.image" alt="" />
                <div class="title">
                  <p>{{ item.store_name }}</p>
                  <p v-if="item.max_price && item.max_price != item.price">
                    {{ item.price | price }}元起
                  </p>
                  <p v-else>{{ item.price | price }}元</p>
                </div>
              </li>
            </a-spin>
          </ul>
          <!-- <div class="title backgroundThem">图书分类</div> -->
          <!-- <a-spin :spinning="categorySpinning">
            <div class="content">
              <a-popover placement="right" v-for="(item, i) in categoryList" :key="i">
                <template #content v-if="item.children.length > 0">
                  <div v-for="(list, ii) in item.children" :key="ii" style="width: 760px">
                    <div style="
                                color: #505050;
                                margin-bottom: 8px;
                                cursor: pointer;
                              " @click="
                                $router.push({
                                  path: 'mealGoods',
                                  query: {
                                    type: list.type,
                                    cateId: list.id,
                                    pid: item.id,
                                    pid2: list.id,
                                    level: 2,
                                  },
                                })
                              ">
                      {{ list.cate_name }}
                    </div>
                    <a-row style="margin-left: 8px" v-if="list.children != []">
                      <a-col :span="5" v-for="(ll, iii) in list.children" :key="iii" class="sanji" @click="
                        $router.push({
                          path: 'mealGoods',
                          query: {
                            type: ll.type,
                            cateId: ll.id,
                            pid: item.id,
                            pid2: list.id,
                            pid3: ll.id,
                            level: 0,
                          },
                        })
                      ">
                        <img :src="ll.pic" alt="" class="img" v-if="ll.pic != ''" />
                        <span class="span">{{ ll.cate_name }}</span>
                      </a-col>
                    </a-row>
                  </div>
                </template>
                <div class="two_title" @click="
                  $router.push({
                    path: 'mealGoods',
                    query: {
                      type: item.type,
                      cateId: item.id,
                      pid: 0,
                      level: 1,
                    },
                  })
                ">
                  <span>{{ item.cate_name }}</span>
                  <a-icon type="right" />
                </div>
              </a-popover>
            </div>
          </a-spin> -->
        </div>
        <div
          class="up_middle"
          :style="isShowCart ? '' : 'flex:1;flex-shrink:0;'"
        >
          <notice-model ref="noticeModel"></notice-model>
          <a-spin :spinning="bannerSpinning">
            <div class="up_middle_up">
              <a-carousel
                class="carousel"
                v-if="bannerList.length > 0"
                arrows
                autoplay
              >
                <div
                  slot="prevArrow"
                  class="custom-slick-arrow"
                  style="left: 10px; z-index: 1"
                >
                  <a-icon type="left-circle" />
                </div>
                <div
                  slot="nextArrow"
                  class="custom-slick-arrow"
                  style="right: 10px"
                >
                  <a-icon type="right-circle" />
                </div>
                <img
                  v-for="(item, i) in bannerList"
                  :key="i"
                  style="object-fit: cover"
                  :src="item.banner_url"
                  alt="#"
                  @click="
                    $router.push({
                      path: 'goodsDetail',
                      query: { id: item.product_id },
                    })
                  "
                />
              </a-carousel>
            </div>
          </a-spin>
          <a-spin :spinning="recommendSpinning">
            <div class="up_middle_down">
              <div
                class="up_middle_down_left"
                v-for="(item, i) in recommend"
                :key="i"
              >
                <a-card hoverable style="min-width: 200px">
                  <div class="up_middle_down_left_flex">
                    <div class="taocan_box">
                      <div class="taocan">
                        <img
                          src="@/assets/img/taocan.png"
                          alt=""
                          class="taocan_img"
                        />
                        <span>套餐</span>
                      </div>
                      <img
                        style="object-fit: contain"
                        slot="cover"
                        alt="example"
                        class="img"
                        :src="item.recommendImage"
                      />
                    </div>
                    <div class="up_middle_down_content">
                      <div>
                        <div
                          class="store_name font_w line2"
                          style="text-align: left"
                        >
                          {{ item.storeName }}
                        </div>
                        <div class="box_button">
                          <div
                            class="money"
                            v-if="
                              item.max_price && item.max_price != item.price
                            "
                          >
                            ¥{{ item.price | price }}起
                          </div>
                          <div class="money" v-else>
                            ¥{{ item.price | price }}
                          </div>
                          <del class="del"> ¥{{ item.otPrice | price }} </del>
                        </div>
                      </div>
                      <div class="box_buttons">
                        <a-button
                          class="mr13"
                          @click="
                            $router.push({
                              path: '/bookBuy/booksBatch',
                            })
                          "
                        >
                          更多套餐
                        </a-button>
                        <a-button
                          type="primary"
                          @click="
                            $router.push({
                              path: 'goodsDetail',
                              query: { id: item.productId },
                            })
                          "
                        >
                          查看详情
                        </a-button>
                      </div>
                    </div>
                  </div>
                </a-card>
              </div>
              <!-- <div class="up_middle_down_left" v-for="(item, i) in recommend" :key="i">
                <a-card hoverable style="min-width: 200px;">
                  <div class="taocan_box">
                    <div class="taocan">
                      <img src="@/assets/img/taocan.png" alt="" class="taocan_img" />
                      <span>套餐</span>
                    </div>
                    <img slot="cover" alt="example" class="img" :src="item.recommendImage" />
                  </div>
                  <a-card-meta :title="item.storeName">
                    <template slot="description">
                      <div class="description font_w line2" style="text-align: left">
                        {{ item.storeInfo }}
                      </div>
                      <div class="book_situation">
                        <div class="box">
                          <div class="num">{{ item.currentNumber }}</div>
                          <div class="text">书籍数量</div>
                        </div>
                        <a-divider type="vertical" style="height: 30px;position: relative;top: 4px;margin: 0 20px;" />
                        <div class="box">
                          <div class="num">{{ item.kind }}</div>
                          <div class="text">书籍种类</div>
                        </div>
                        <a-divider type="vertical" style="height: 30px;position: relative;top: 4px;margin: 0 20px;" />
                        <div class="box">
                          <div class="num">{{ item.cate_name[0] }}</div>
                          <div class="text">分类名称</div>
                        </div>
                      </div>
                      <div class="box_button">
                        <div>
                          <div class="money">¥{{ item.price | price }}</div>
                          <del class="del">原价：¥{{ item.otPrice | price }}</del>
                        </div>
                        <div>
                          <a-button class="mr13" @click="
                            $router.push({
                              path: '/bookBuy/booksBatch',
                            })
                          ">更多套餐</a-button>
                          <a-button type="primary" @click="
                            $router.push({
                              path: 'goodsDetail',
                              query: { id: item.productId },
                            })
                          ">查看详情</a-button>
                        </div>
                      </div>
                    </template>
                  </a-card-meta>
                </a-card>
              </div> -->
              <div class="no_detail" v-if="recommend.length == 0">
                <img src="@/assets/img/no_detail.png" alt="" />
                <span>暂无内容~</span>
              </div>
            </div>
          </a-spin>
        </div>
        <div class="up_right" v-if="isShowCart">
          <shopping-cart ref="rightCart" @getCartNum="updateCart" />
          <!-- <a-spin :spinning="hotSpinning">
            <div class="top">
              <img src="@/assets/img/hot.svg" alt="" />
              <span>图书热卖</span>
            </div>
            <div class="bottom">
              <div class="bottom_content" v-for="item in hot" :key="item.productId" @click="
                $router.push({
                  path: 'goodsDetail',
                  query: { id: item.productId },
                })
              ">
                <img :src="item.image" alt="" />
                <div style="width: 66%">
                  <div class="book_name">{{ item.storeName }}</div>
                  <div class="book_money">{{ item.price | price }}元</div>
                </div>
              </div>
            </div>
            <div class="see_more" @click="
              $router.push({
                path: 'mealGoods',
                query: { type: 1, cateId: 0, level: 1 },
              })
            ">
              查看更多
            </div>
          </a-spin> -->
        </div>
      </div>
      <div class="middle">
        <a-tabs default-active-key="0" @change="callback" :heigth="72">
          <a-tab-pane
            :tab="item.cate_name"
            v-for="item in categoryList2"
            :key="item.id"
          >
          </a-tab-pane>
        </a-tabs>
        <div class="scroll_box" ref="box">
          <a-row class="list_box" :gutter="[16, 16]">
            <a-col
              class="list"
              :xl="6"
              :xxl="4"
              :sm="12"
              v-for="(item, i) in list"
              :key="i"
            >
              <a-card
                hoverable
                @click.native.prevent="
                  $router.push({
                    path: 'goodsDetail',
                    query: { id: item.product_id },
                  })
                "
              >
                <div
                  style="height: 224px; text-align: center; margin-bottom: 10px"
                >
                  <img slot="cover" alt="example" :src="item.image" />
                </div>
                <a-card-meta>
                  <template slot="description">
                    <div class="line2 product_name">
                      <span class="presell" v-if="item.is_oversold == 1">
                        预售
                      </span>
                      {{ item.store_name }}
                    </div>
                    <div class="box_button">
                      <div>
                        <div
                          class="money"
                          v-if="item.max_price && item.max_price != item.price"
                        >
                          ¥{{ item.price | price }}起
                        </div>
                        <div class="money" v-else>
                          ¥{{ item.price | price }}
                        </div>
                        <del class="del"> ¥{{ item.ot_price | price }} </del>
                      </div>
                      <!-- @click.stop="addCart(0,item.product_id,item.unique)" -->
                      <a-button
                        type="primary"
                        size="small"
                        @click.stop="openCardNum(item)"
                      >
                        {{
                          item.good_type == 3 ||
                          item.is_cust == 1 ||
                          item.pay_type_info.is_dandu == 1
                            ? "立即购买"
                            : "加入购物车"
                        }}
                      </a-button>
                      <!-- <a-button class="green button" @click="
                        $router.push({
                          path: 'goodsDetail',
                          query: { id: item.product_id },
                        })
                      ">查看详情</a-button> -->
                    </div>
                  </template>
                </a-card-meta>
              </a-card>
            </a-col>
            <a-col span="24" v-show="noData" class="no_data">
              暂无更多数据~
            </a-col>
          </a-row>
          <a-spin :spinning="spinning"> </a-spin>
        </div>

        <!-- <div style="margin-top: 20px; text-align: right">
          <a-pagination
            v-model="current"
            :page-size-options="pageSizeOptions"
            :page-size="pageSize"
            :total="total"
            show-size-changer
            v-if="total != 0"
            @change="onChange1"
            @showSizeChange="onShowSizeChange"
          >
          </a-pagination>
        </div> -->
      </div>
    </div>
    <cart-num
      ref="cartNum"
      :list="specificationList"
      :minNumber="minNumber"
      :maxNumber="maxNumber"
      :type="
        addGood.good_type == 3 ||
        addGood.is_cust == 1 ||
        (addGood.pay_type_info && addGood.pay_type_info.is_dandu == 1)
          ? 1
          : 0
      "
      @addCart="addCart"
    />
    <shopping-cart-fixed
      class="fixed"
      ref="shoppingCart"
      @getCartNum="updateCart"
    />
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import cartNum from "@/components/addCartNum";
import shoppingCartFixed from "@/components/shoppingCart/index-fixed";
import shoppingCart from "@/components/shoppingCart/index";
import noticeModel from "@/components/notice";
import { mapState } from "vuex";
import {
  addCart,
  category,
  advList,
  productList,
  recommendList,
  detail,
  hotRanking,
} from "@/api/allGoods";
import { addressList } from "@/api/receivingAdress";
import { price } from "@/utils/filters";
export default {
  name: "AllGoods",
  components: {
    PageLayout,
    cartNum,
    shoppingCart,
    shoppingCartFixed,
    noticeModel,
  },
  filters: { price },
  data() {
    return {
      categoryList: [],
      categoryList2: [],
      bannerList: [],
      list: [],
      pageSizeOptions: ["12", "24", "36", "48", "60"],
      current: 1,
      pageSize: 24,
      total: 0,
      id: "",
      recommend: [],
      hot: [],
      keywords: "",
      addressId: 0,
      classTitleList: ["热门图书", "热门物料"],
      selectClass: 0,
      spinning: false,
      RankingSpinning: false,
      bannerSpinning: false,
      recommendSpinning: false,
      hotSpinning: false,
      noData: false,
      addGood: {}, // 要加入购物车/立即购买的商品
      isShowCart: true,
      specificationList: [], // 要加入购物车/立即购买的规格信息
      RankingList: [],
      minNumber: 1,
      maxNumber: 9999,
    };
  },
  computed: {
    ...mapState({
      cartNum: (state) => {
        return state.cart.total;
      },
    }),
  },
  destroyed() {
    window.removeEventListener("resize", this.setShow);
  },
  created() {
    this.isShowCart = document.body.offsetWidth >= 1600;
    window.addEventListener("resize", this.setShow);
    this.getCategory(1);
    this.getRanking(1);
    this.$store.dispatch("cart/getNum");
    // cartCount().then((res) => {
    //   if (res.data.status == 200) {
    //     this.cartNum = res.data.data.count;
    //   } else {
    //     this.$message.error(res.data.msg);
    //   }
    // });
    this.bannerSpinning = true;
    advList()
      .then((res) => {
        this.bannerList = res.data.data.list;
      })
      .finally(() => {
        this.bannerSpinning = false;
      });
    this.recommendSpinning = true;
    recommendList(2, {})
      .then((res) => {
        this.recommend = res.data.data;
      })
      .finally(() => {
        this.recommendSpinning = false;
      });
    addressList().then((res) => {
      if (res.data.data.list.length > 0) {
        this.addressId = res.data.data.list[0].id;
      }
    });
    this.getList(this.current, this.pageSize, this.id, this.keywords);
    // this.getCartNum();
  },
  mounted() {
    this.$refs.box.addEventListener("scroll", this.scrollFn);
  },
  methods: {
    getRanking(num) {
      this.RankingSpinning = true;
      hotRanking({
        genre: num,
      })
        .then((res) => {
          this.RankingSpinning = false;
          this.RankingList = res.data.data;
        })
        .catch(() => {
          this.RankingSpinning = false;
        });
    },
    updateCart() {
      this.$store.dispatch("cart/getNum");
      this.$store.dispatch("cart/getList");
      // this.$refs.rightCart.getNum()
      // this.$refs.rightCart.getList()
      // this.$refs.shoppingCart.getNum()
      // this.$refs.shoppingCart.getList()
    },
    // getCartNum() {
    //   cartCount().then((res) => {
    //     if (res.data.status == 200) {
    //       this.cartNum = res.data.data.count;
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
    setShow() {
      if (document.body.offsetWidth < 1600) {
        this.isShowCart = false;
      } else {
        this.isShowCart = true;
      }
    },
    openCardNum(record) {
      //获取多规格信息
      const hide = this.$message.loading("请稍等...", 0);
      this.getProductDetail(record.product_id)
        .then((res) => {
          hide();
          this.addGood = record;
          this.specificationList = res;
          this.$refs.cartNum.visible = true;
        })
        .catch(() => {
          hide();
        });
    },
    getProductDetail(id) {
      return new Promise((resolve, reject) => {
        detail(id)
          .then((res) => {
            this.minNumber = res.data.data.storeInfo.minimum_quantity;
            this.maxNumber =
              res.data.data.storeInfo.is_purchase == 1
                ? res.data.data.storeInfo.purchase_limit
                : 9999;
            resolve(res.data.data.productValue);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrollFn(e) {
      console.log("scrollFn");
      const scrollTop = e.target.scrollTop;
      const windowHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      if (scrollTop + windowHeight === scrollHeight) {
        if (this.total <= this.list.length) {
          this.noData = true;
          return false;
        }
        let isbottom = true;
        this.getList(
          this.current,
          this.pageSize,
          this.id,
          this.keywords,
          isbottom
        );
      }
    },
    getCategory(type) {
      this.categorySpinning = true;
      category({
        pid: 0,
        level: 0,
        type: type,
      })
        .then((res) => {
          this.categoryList = res.data.data;
          let list = JSON.parse(JSON.stringify(res.data.data));
          list.unshift({
            cate_name: "全部",
            id: 0,
          });
          if (type == 1) {
            this.categoryList2 = list;
          }
        })
        .finally(() => {
          this.categorySpinning = false;
        });
    },
    changeClass(i) {
      this.selectClass = i;
      this.getRanking(i + 1);
    },
    onSearch(val) {
      this.$router.push({
        path: "mealGoods",
        query: { type: 1, cateId: 0, level: 1, keywords: val },
      });
      // this.getList(this.current, this.pageSize, this.id, this.keywords);
    },
    getList(page, limit, id, keywords, isBottom) {
      if (this.noData) {
        return false;
      }
      if (isBottom) {
        page++;
      }
      this.spinning = true;
      productList({
        page: page,
        limit: limit,
        cate_id: id,
        keywords: keywords,
      })
        .then((res) => {
          if (res.data.data.count < this.pageSize && res.data.data.count > 0) {
            this.noData = true;
          }
          if (res.data.data.count == 0) {
            this.total = 0;
            this.noData = true;
          } else if (!isBottom) {
            this.list = res.data.data.list;
            this.total = res.data.data.count;
          } else {
            if (res.data.data.list.length > 0) {
              this.current++;
            }
            this.list = [...this.list, ...res.data.data.list];
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    handleClick(e) {
      console.log("click ", e);
    },
    callback(key) {
      this.id = key;
      this.noData = false;
      this.current = 1;
      this.pageSize = 20;
      this.list = [];
      this.getList(this.current, this.pageSize, key, this.keywords);
    },
    onChange1(page, pageSize) {
      this.pageSize = pageSize;
      this.current = page;
      this.getList(page, pageSize, this.id, this.keywords);
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.getList(current, pageSize, this.id, this.keywords);
    },
    addCart(addInfo) {
      addCart({
        productId: this.addGood.product_id,
        cartNum: addInfo.value,
        uniqueId: addInfo.uniqueId,
        is_new: addInfo.type,
      }).then((res) => {
        if (addInfo.type == 0) {
          // 加入购物车
          this.$message.success("已加入购物车");
          this.$refs.cartNum.visible = false;
          this.$refs.cartNum.form.value = 1;
          this.$store.dispatch("cart/getList", { page: 1, pageSize: 1000 });
          this.$store.dispatch("cart/getNum");
        } else {
          // 立即购买
          this.$router.push({
            path: "submitOrder",
            query: { cartId: res.data.data.cartId, new: 1 },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scope>
@import "index";
</style>
<style scoped>
.fixed {
  position: fixed;
  right: 32px;
  bottom: 60px;
  z-index: 3;
}
.up_middle_down_left /deep/ .ant-card-body {
  padding: 16px;
}
.up_middle_down_left /deep/ .ant-card-hoverable {
  cursor: default !important;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 403px;
  line-height: 403px;
  background: #364d79;
  overflow: hidden;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.4);
  opacity: 0.3;
  font-size: 35px !important;
  border-radius: 20px;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-prev {
  left: 20px !important;
  top: 45%;
}
.ant-carousel >>> .slick-next {
  right: 20px !important;
  top: 45%;
}
</style>
