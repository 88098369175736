<template>
  <div>
    <a-modal
      v-model="visible"
      :title="type === 1 ? '立即购买' : '加入购物车'"
      width="50%"
      :destroyOnClose="true"
      :afterClose="
        () => {
          this.form = { value: 1, uniqueId: undefined };
        }
      "
      @ok="handleOk"
    >
      <a-form-model
        :model="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }"
      >
        <a-form-model-item label="规格" style="margin: 0">
          <a-radio-group :value="form.uniqueId" @change="onChange">
            <a-radio-button
              style="margin: 4px 0"
              v-for="(item, index) in list"
              :key="index"
              :value="item.unique"
              :disabled="item.is_sale == 0"
            >
              {{ item.suk }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="库存" style="margin: 0">
          {{ cur_stock }}
        </a-form-model-item>
        <a-form-model-item label="数量">
          <a-input-number
            v-model="form.value"
            :min="minNumber"
            :max="Math.min(maxNumber, cur_stock)"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    minNumber: {
      type: Number,
      default: 1,
    },
    maxNumber: {
      type: Number,
      default: 9999,
    },
    // 0.加入购物车 1.立即购买
    type: {
      type: Number,
      default: 0,
    },
  },
  name: "",
  data() {
    return {
      visible: false,
      form: {
        value: 1,
        uniqueId: undefined,
      },
    };
  },
  computed: {
    cur_stock() {
      var stock =
        (this.list.filter((item) => item.unique == this.form.uniqueId)[0] || {})
          .stock || 0;
      return Math.max(0, stock);
    },
  },
  created() {},
  watch: {
    visible(val) {
      if (val) {
        const arr = this.list.filter((item) => item.is_sale == 1);
        if (arr.length > 0) {
          this.form.uniqueId = arr[0].unique;
        } else {
          this.form.uniqueId = undefined;
        }
      }
    },
  },
  methods: {
    changeNumber(e) {
      console.log(e);
    },
    onChange(e) {
      this.form.uniqueId = e.target.value;
    },
    handleOk() {
      let form = Object.assign({ type: this.type }, this.form);
      form.value = form.value < this.minNumber ? this.minNumber : form.value;
      this.$emit("addCart", form);
    },
  },
};
</script>
<style scoped></style>
