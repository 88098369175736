var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout',[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"action"},slot:"action"},[_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入关键词","enter-button":""},on:{"search":_vm.onSearch},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),_c('div',{staticClass:"buy_cart",on:{"click":function($event){return _vm.$router.push({ path: 'buyCart' })}}},[_c('span',[_vm._v("购物车")]),_c('a-badge',{attrs:{"count":_vm.cartNum,"number-style":{
          backgroundColor: 'transparent',
          color: '#3ab887',
          boxShadow: '0 0 0 1px #3ab887 inset',
          marginLeft: '9px',
          top: '-1px',
        }}})],1)],1),_c('div',{attrs:{"id":"allGoods"}},[_c('div',{staticClass:"up"},[_c('div',{staticClass:"up_left"},[_c('div',{staticClass:"title_box"},_vm._l((_vm.classTitleList),function(item,i){return _c('p',{key:i,class:_vm.selectClass == i ? 'selectP' : '',on:{"click":function($event){return _vm.changeClass(i)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('ul',{staticClass:"content"},[_c('a-spin',{attrs:{"spinning":_vm.RankingSpinning}},[(_vm.RankingList.length == 0)?_c('div',{staticClass:"no_detail no_detail_l"},[_c('img',{attrs:{"src":require("@/assets/img/no_detail.png"),"alt":""}}),_c('span',[_vm._v("暂无内容~")])]):_vm._e(),_vm._l((_vm.RankingList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.$router.push({
                  path: 'goodsDetail',
                  query: { id: item.id },
                })}}},[_c('span',{class:['index', 'index' + index]},[_vm._v(_vm._s(index + 1))]),_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":item.image,"alt":""}}),_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(item.store_name))]),(item.max_price && item.max_price != item.price)?_c('p',[_vm._v(" "+_vm._s(_vm._f("price")(item.price))+"元起 ")]):_c('p',[_vm._v(_vm._s(_vm._f("price")(item.price))+"元")])])])})],2)],1)]),_c('div',{staticClass:"up_middle",style:(_vm.isShowCart ? '' : 'flex:1;flex-shrink:0;')},[_c('notice-model',{ref:"noticeModel"}),_c('a-spin',{attrs:{"spinning":_vm.bannerSpinning}},[_c('div',{staticClass:"up_middle_up"},[(_vm.bannerList.length > 0)?_c('a-carousel',{staticClass:"carousel",attrs:{"arrows":"","autoplay":""}},[_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"left":"10px","z-index":"1"},attrs:{"slot":"prevArrow"},slot:"prevArrow"},[_c('a-icon',{attrs:{"type":"left-circle"}})],1),_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"right":"10px"},attrs:{"slot":"nextArrow"},slot:"nextArrow"},[_c('a-icon',{attrs:{"type":"right-circle"}})],1),_vm._l((_vm.bannerList),function(item,i){return _c('img',{key:i,staticStyle:{"object-fit":"cover"},attrs:{"src":item.banner_url,"alt":"#"},on:{"click":function($event){return _vm.$router.push({
                    path: 'goodsDetail',
                    query: { id: item.product_id },
                  })}}})})],2):_vm._e()],1)]),_c('a-spin',{attrs:{"spinning":_vm.recommendSpinning}},[_c('div',{staticClass:"up_middle_down"},[_vm._l((_vm.recommend),function(item,i){return _c('div',{key:i,staticClass:"up_middle_down_left"},[_c('a-card',{staticStyle:{"min-width":"200px"},attrs:{"hoverable":""}},[_c('div',{staticClass:"up_middle_down_left_flex"},[_c('div',{staticClass:"taocan_box"},[_c('div',{staticClass:"taocan"},[_c('img',{staticClass:"taocan_img",attrs:{"src":require("@/assets/img/taocan.png"),"alt":""}}),_c('span',[_vm._v("套餐")])]),_c('img',{staticClass:"img",staticStyle:{"object-fit":"contain"},attrs:{"slot":"cover","alt":"example","src":item.recommendImage},slot:"cover"})]),_c('div',{staticClass:"up_middle_down_content"},[_c('div',[_c('div',{staticClass:"store_name font_w line2",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(item.storeName)+" ")]),_c('div',{staticClass:"box_button"},[(
                            item.max_price && item.max_price != item.price
                          )?_c('div',{staticClass:"money"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.price))+"起 ")]):_c('div',{staticClass:"money"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.price))+" ")]),_c('del',{staticClass:"del"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.otPrice))+" ")])])]),_c('div',{staticClass:"box_buttons"},[_c('a-button',{staticClass:"mr13",on:{"click":function($event){return _vm.$router.push({
                            path: '/bookBuy/booksBatch',
                          })}}},[_vm._v(" 更多套餐 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
                            path: 'goodsDetail',
                            query: { id: item.productId },
                          })}}},[_vm._v(" 查看详情 ")])],1)])])])],1)}),(_vm.recommend.length == 0)?_c('div',{staticClass:"no_detail"},[_c('img',{attrs:{"src":require("@/assets/img/no_detail.png"),"alt":""}}),_c('span',[_vm._v("暂无内容~")])]):_vm._e()],2)])],1),(_vm.isShowCart)?_c('div',{staticClass:"up_right"},[_c('shopping-cart',{ref:"rightCart",on:{"getCartNum":_vm.updateCart}})],1):_vm._e()]),_c('div',{staticClass:"middle"},[_c('a-tabs',{attrs:{"default-active-key":"0","heigth":72},on:{"change":_vm.callback}},_vm._l((_vm.categoryList2),function(item){return _c('a-tab-pane',{key:item.id,attrs:{"tab":item.cate_name}})}),1),_c('div',{ref:"box",staticClass:"scroll_box"},[_c('a-row',{staticClass:"list_box",attrs:{"gutter":[16, 16]}},[_vm._l((_vm.list),function(item,i){return _c('a-col',{key:i,staticClass:"list",attrs:{"xl":6,"xxl":4,"sm":12}},[_c('a-card',{attrs:{"hoverable":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                  path: 'goodsDetail',
                  query: { id: item.product_id },
                })}}},[_c('div',{staticStyle:{"height":"224px","text-align":"center","margin-bottom":"10px"}},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.image},slot:"cover"})]),_c('a-card-meta',[_c('template',{slot:"description"},[_c('div',{staticClass:"line2 product_name"},[(item.is_oversold == 1)?_c('span',{staticClass:"presell"},[_vm._v(" 预售 ")]):_vm._e(),_vm._v(" "+_vm._s(item.store_name)+" ")]),_c('div',{staticClass:"box_button"},[_c('div',[(item.max_price && item.max_price != item.price)?_c('div',{staticClass:"money"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.price))+"起 ")]):_c('div',{staticClass:"money"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.price))+" ")]),_c('del',{staticClass:"del"},[_vm._v(" ¥"+_vm._s(_vm._f("price")(item.ot_price))+" ")])]),_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.openCardNum(item)}}},[_vm._v(" "+_vm._s(item.good_type == 3 || item.is_cust == 1 || item.pay_type_info.is_dandu == 1 ? "立即购买" : "加入购物车")+" ")])],1)])],2)],1)],1)}),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.noData),expression:"noData"}],staticClass:"no_data",attrs:{"span":"24"}},[_vm._v(" 暂无更多数据~ ")])],2),_c('a-spin',{attrs:{"spinning":_vm.spinning}})],1)],1)]),_c('cart-num',{ref:"cartNum",attrs:{"list":_vm.specificationList,"minNumber":_vm.minNumber,"maxNumber":_vm.maxNumber,"type":_vm.addGood.good_type == 3 ||
      _vm.addGood.is_cust == 1 ||
      (_vm.addGood.pay_type_info && _vm.addGood.pay_type_info.is_dandu == 1)
        ? 1
        : 0},on:{"addCart":_vm.addCart}}),_c('shopping-cart-fixed',{ref:"shoppingCart",staticClass:"fixed",on:{"getCartNum":_vm.updateCart}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }