<template>
  <div>
    <div class="up_middle_notice">
      <img src="@/assets/img/notice.svg" alt="" />
      <vue-seamless-scroll
        :data="listData"
        :class-option="optionSingleHeight"
        class="warp"
      >
        <ul class="item">
          <li v-for="(item, index) in listData" :key="index">
            <span
              class="title"
              v-text="item.title"
              style="margin-right: 16px"
            ></span
            ><span class="date" v-text="item.add_time"></span>
          </li>
        </ul>
      </vue-seamless-scroll>
      <div style="cursor: pointer" @click="openNotice">
        查看更多<a-icon type="right" />
      </div>
    </div>
    <a-modal
      v-model="visible"
      title="公告"
      :width="750"
      :footer="null"
      :forceRender="true"
    >
      <div
        class="notice_box"
        v-for="(item, index) in listData"
        :key="index"
        @click="openDetail(item, index)"
      >
        <div
          class="notice_title"
          :style="{
            marginBottom: item._taggle ? '16px' : '8px',
          }"
        >
          <p class="fontw line1">{{ item.title }}</p>
          <div class="check">
            <span>查看详情</span>
            <a-icon type="down" class="check_icon" v-show="item._taggle" />
            <a-icon type="up" class="check_icon" v-show="!item._taggle" />
          </div>
        </div>
        <div v-if="item._loading" style="text-align: center">
          <a-spin />
        </div>
        <div
          class="content"
          :style="{
            height: item._taggle ? 'auto' : '0',
            transform: `scaleY(${item._taggle ? 1 : 0})`,
          }"
          v-html="item.content"
          v-if="!item._loading"
        ></div>
        <p :class="item._taggle ? 'mt16' : ''">{{ item.add_time }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { articleList, articleDetails } from "@/api/allGoods";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "",
  components: { vueSeamlessScroll },
  data() {
    return {
      visible: false,
      form: {
        page: 1,
        limit: 10,
      },
      listData: [
        {
          title: "还在开发中，敬请期待1",
          date: "2023-8-7",
          _taggle: false,
        },
        {
          title: "还在开发中，敬请期待2",
          date: "2023-8-8",
          _taggle: false,
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 24,
      };
    },
  },
  methods: {
    openDetail(item, index) {
      this.listData.forEach((item, i) => {
        if (i == index) {
          console.log(item._taggle);
          if (!item._taggle) {
            this.getDetail(item.id, i);
          }
          this.$set(this.listData[i], "_taggle", !item._taggle);
        } else {
          this.$set(this.listData[i], "_taggle", i == index);
        }
      });
    },
    getDetail(id, index) {
      this.$set(this.listData[index], "_loading", true);
      articleDetails(id).then((res) => {
        this.$set(this.listData[index], "content", res.data.data.content);
        this.$set(this.listData[index], "_loading", false);
      });
    },
    openNotice() {
      this.visible = true;
    },
    getList() {
      articleList(this.form).then((res) => {
        this.listData = res.data.data.map((item) => {
          item._taggle = false;
          item._loading = false;
          return item;
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.notice_box {
  background-color: #f7f7f7;
  padding: 24px;
  margin-bottom: 16px;
  cursor: pointer;

  p {
    padding: 0;
    margin: 0;
  }

  .content {
    overflow: hidden;
    transition: all 0.3s;
    transform-origin: top;
  }

  .notice_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .mt16 {
    margin-top: 16px;
  }

  .check {
    color: #3ab887;
    font-size: 14px;
    flex-shrink: 0;
  }

  .check_icon {
    margin-left: 4px;
    font-size: 12px;
  }

  .fontw {
    font-weight: bold;
    flex: 1;
  }

  .line1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
